import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { HelperText } from './Password.styles';

import { InputAdornment, FormControl, IconButton, OutlinedInput, InputLabel } from '@mui/material';
interface IPassword {
  value: string;
  setValue: (value: string) => void;
  label?: string;
  isNeedValid?: boolean;
  showOnlyTextInvalidPassword?: boolean;
  error?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLImageElement>) => void;
}
const Password: React.FC<IPassword> = ({
  isNeedValid,
  showOnlyTextInvalidPassword,
  value,
  setValue,
  label,
  error,
  onKeyPress,
}) => {
  const [errorText, setErrorText] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const validate = () => {
    setValue(value.trim());
    if (isNeedValid) setErrorText(error ? error : '');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (error === '') {
      setErrorText(error);
    }
  }, [error]);

  return (
    <FormControl variant="outlined" fullWidth margin="dense">
      <InputLabel sx={{ backgroundColor: '#fff' }} htmlFor="outlined-adornment-password">
        {label ? label : 'Password'}
      </InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={value}
        onBlur={validate}
        onChange={onChange}
        onKeyPress={onKeyPress}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />

      {!!errorText.length && (
        <HelperText isshow={(!!errorText.length).toString()} error id="accountId-error">
          {showOnlyTextInvalidPassword ? 'Invalid password' : errorText}
        </HelperText>
      )}
    </FormControl>
  );
};

export default observer(Password);
