import { useState } from 'react';
import { observer } from 'mobx-react';
import { ConnectButtonWrapper, Link } from './ModalConnectToCGM.styles';
import { Button } from '@mui/material';
import { ConnectionImg, ConnetionTitle, ConnetionText, ConnetionImgWrapper } from '../CGMLink.styles';
import nightscout from 'assets/images/nightscout.png';
import { Textfield, Password } from 'components';
import store from 'store';

const FreestyleLibreForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [endpoint, setEndpoint] = useState('');
  const [apiSecret, setApiSecret] = useState('');

  const { connectNightscoutApi } = store;

  const connect = async () => {
    await connectNightscoutApi(endpoint, apiSecret);
    onSubmit();
  };

  return (
    <>
      <ConnetionImgWrapper>
        <ConnectionImg src={nightscout} />
      </ConnetionImgWrapper>
      <ConnetionTitle>Nightscout</ConnetionTitle>
      <ConnetionText>
        Use your Nightscout api credentials to link your data. Credentials can be found within the Nightscout app on the
        wearer's mobile device.
        <br />
        For additional help, view the setup guide{' '}
        <Link target="_blank" href="https://glowcose.com/setupv2">
          here
        </Link>
        .
      </ConnetionText>
      <Textfield value={endpoint} label={'Endpoint'} setValue={setEndpoint} />
      <Password value={apiSecret} label={'Api Secret'} setValue={setApiSecret} />
      <ConnectButtonWrapper>
        <Button
          color="primary"
          size="normal"
          disabled={!endpoint || !apiSecret}
          fullWidth
          variant="contained"
          onClick={connect}
        >
          Submit
        </Button>
      </ConnectButtonWrapper>
    </>
  );
};

export default observer(FreestyleLibreForm);
