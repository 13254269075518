import { styled } from '@mui/material/styles';

export const ConnetionBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '48px 40px 10px 0',
  flex: '0 0 240px',
  textAlign: 'center',
  justifyContent: 'start',
});

export const ConnetionBlockNeedCGMConn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '40px 0 10px',
  flex: '0 0 200px',
  textAlign: 'center',
  justifyContent: 'start',
});
