import { useState } from 'react';
import { observer } from 'mobx-react';
import { ConnectButtonWrapper, Link } from './ModalConnectToCGM.styles';
import { Button } from '@mui/material';
import { ConnectionImg, ConnetionTitle, ConnetionText, ConnetionImgWrapper } from '../CGMLink.styles';
import freestyler from 'assets/images/freestylelibre.jpg';
import { Textfield, Password } from 'components';
import store from 'store';

const FreestyleLibreForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { connectFreeStyleLibreApi } = store;

  const connect = async () => {
    await connectFreeStyleLibreApi(email, password);
    onSubmit();
  };

  return (
    <>
      <ConnetionImgWrapper>
        <ConnectionImg src={freestyler} />
      </ConnetionImgWrapper>
      <ConnetionTitle>FreeStyle Libre</ConnetionTitle>
      <ConnetionText>
        As a prerequisite, you must install the LibreLinkUp mobile application and log in using your LibreView account
        credentials. Once finished, enter your credentials to link your CGM data.
        <br />
        For additional help, view the setup guide{' '}
        <Link target="_blank" href="https://glowcose.com/setupv2">
          here
        </Link>
        .
      </ConnetionText>
      <Textfield value={email} label={'Email'} setValue={setEmail} />
      <Password value={password} label={'Password'} setValue={setPassword} />
      <ConnectButtonWrapper>
        <Button
          color="primary"
          size="normal"
          disabled={!email || !password}
          fullWidth
          variant="contained"
          onClick={connect}
        >
          Submit
        </Button>
      </ConnectButtonWrapper>
    </>
  );
};

export default observer(FreestyleLibreForm);
