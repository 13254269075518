import React from 'react';
import { observer } from 'mobx-react';
import {
  ModalWrapper,
  ModalContenWrapper,
  ModalButtonWrapper,
  ModalTitle,
  ModalTextTop,
  ModalTextBottom,
  CloseIconButton,
} from 'views/Main/ModalLiveDevice/ModalLiveDevice.styles';
import { Modal, Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useCallback } from 'react';
import { ConnectionType, ConnectionName } from '_constants';

interface Props {
  open: boolean;
  connectingTo: ConnectionType;
  setOpen: (val: boolean) => void;
  onComplete: () => void;
}

const ModalAutolDisconnectCGM: React.FC<Props> = ({ open = false, connectingTo, setOpen, onComplete }) => {
  const onClose = () => {
    setOpen(false);
  };

  const newConnect = useCallback(() => {
    setOpen(false);
    onComplete();
  }, [onComplete, setOpen]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalWrapper>
        <ModalContenWrapper>
          <CloseIconButton onClick={onClose}>
            <CloseRoundedIcon />
          </CloseIconButton>
          <ModalTitle>Disconnect from the service</ModalTitle>
          <ModalTextTop />
          <ModalTextBottom>
            You can link your Glowcose to one CGM service at a time. If you proceed with connection to{' '}
            {ConnectionName[connectingTo]}, you will be disconnected from the other services.
          </ModalTextBottom>
          <ModalButtonWrapper>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="contained"
              onClick={newConnect}
            >
              PROCEED
            </Button>
          </ModalButtonWrapper>
        </ModalContenWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(ModalAutolDisconnectCGM);
