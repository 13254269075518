import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '_constants';
import {
  Content,
  Title,
  Caption,
  SaveButton,
  Wrapper,
  LogoWrapper,
  Logo,
  ContentWrapper,
} from 'views/Login/Login.styles';
import {
  ButtonWrapper,
  ArrowBack,
  ArrowBackLink,
  TitleIcon,
  TitleWrapper,
} from 'views/Login/ForgotPassword/ForgotPassword.styles';
import store from 'store';
import logo from 'assets/icons/glowcose-logo.svg';
import arrow from 'assets/icons/arrow-back.svg';
import lockIcon from 'assets/images/lock-icon.png';
import { Password } from 'components';
import { getPasswordValidateError } from 'utils';

const ResetPassword: React.FC = () => {
  const { completeResetPassword, resetPassword } = store;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const linkExpired = searchParams.get('link_expired');
  const gotoLogin = () => {
    navigate('/' + ROUTES.LOGIN);
  };

  useEffect(() => {
    const err = getPasswordValidateError(newPassword);
    setNewPasswordError(err);
  }, [newPassword]);

  useEffect(() => {
    let err = getPasswordValidateError(confirmNewPassword);
    if (!err && confirmNewPassword && confirmNewPassword !== newPassword) {
      err = 'Password mismatch';
    }
    setConfirmNewPasswordError(err);
  }, [confirmNewPassword, newPassword]);

  const onResetPassword = async () => {
    if (!!(email && token && newPassword && confirmNewPassword && !(newPasswordError || confirmNewPasswordError))) {
      resetPassword(email, token, newPassword, confirmNewPassword);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <LogoWrapper>
          <Logo src={logo} alt="logo" />
        </LogoWrapper>

        <Content>
          {linkExpired ? (
            <Caption>It seems that the reset link has expired or is no longer valid.</Caption>
          ) : completeResetPassword ? (
            <Caption>Your password has been successfully reset</Caption>
          ) : (
            <>
              <TitleWrapper>
                <Title>Reset password</Title> <TitleIcon src={lockIcon} alt=""></TitleIcon>
              </TitleWrapper>
              <Caption>
                Your password has been reset successfully. To proceed, create a new password for your account
              </Caption>
              <Password
                isNeedValid={true}
                key={'newPassword'}
                value={newPassword}
                setValue={setNewPassword}
                error={newPasswordError}
                label={'New Password'}
              />
              <Password
                isNeedValid={true}
                key={'confirmNewPassword'}
                value={confirmNewPassword}
                setValue={setConfirmNewPassword}
                error={confirmNewPasswordError}
                label={'Confirm New Password'}
              />
              <ButtonWrapper>
                <SaveButton
                  disabled={!(newPassword && confirmNewPassword && !(newPasswordError || confirmNewPasswordError))}
                  fullWidth
                  variant="contained"
                  onClick={onResetPassword}
                >
                  Submit
                </SaveButton>
              </ButtonWrapper>
            </>
          )}
          <ArrowBackLink onClick={gotoLogin}>
            <ArrowBack src={arrow} alt="Back to login" /> Back to login
          </ArrowBackLink>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default observer(ResetPassword);
