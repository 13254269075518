import { Button } from '@mui/material';
import { ConnectionType, ConnectionName } from '_constants';
import { ConnectionImg, ConnetionTitle, ConnetionText, ConnetionImgWrapper } from '../CGMLink.styles';
import { ConnetionBlock } from './GMCard.styles';

export const CGMCard = ({
  imgSrc,
  isConnectedTo,
  connectTo,
  handler,
}: {
  imgSrc: string;
  isConnectedTo: boolean;
  connectTo: ConnectionType;
  handler: (arg: ConnectionType) => void;
}) => (
  <ConnetionBlock>
    <ConnetionImgWrapper>
      <ConnectionImg src={imgSrc} />
    </ConnetionImgWrapper>
    <ConnetionTitle>{ConnectionName[connectTo]}</ConnetionTitle>
    <ConnetionText>Connect to {ConnectionName[connectTo]} to link your CGM data.</ConnetionText>
    <Button
      color="primary"
      size="normal"
      fullWidth
      variant={isConnectedTo ? 'outlined' : 'contained'}
      onClick={() => handler(isConnectedTo ? ConnectionType.None : connectTo)}
    >
      {isConnectedTo ? 'Disconnect' : 'Connect'}
    </Button>
  </ConnetionBlock>
);
