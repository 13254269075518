import { styled } from '@mui/material/styles';
import MuiCircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MuiCheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link as MuiLink } from '@mui/material';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  height: '100%',
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: '#F4F5FA',
  height: '100%',
  width: '100%',
});

export const MainBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: '#F4F5FA',
  overflow: 'auto',
  height: '100%',
  width: '100%',
});

export const HeaderBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  padding: '12px 0 24px',
});

export const FooterBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0 24px',
  width: '100%',
});

export const AvatarWrapper = styled('div')({
  display: 'flex',
  height: '40px',
  marginLeft: '20px',
  marginRight: '24px',
  cursor: 'pointer',
});

export const ContentBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '24px',
});

export const ContainerHead = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  margin: '0 24px',
  flexFlow: 'wrap',
  overflow: 'hidden',
});

export const CGMConnectionBlock = styled('div')({
  minHeight: '146px',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '6px',
  flexGrow: '3',
});

export const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Title = styled('div')({
  margin: '22px 0 44px 20px',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: '500',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const Caption = styled('div')({
  fontSize: '16px',
  lineHeight: '20px',
  margin: '0 0 22px 20px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const Icon = styled('div')({
  color: '#00D5C9',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: '100px',
  alignItems: 'center',
});

interface IWrapper {
  colors: string;
}

export const CheckCircleIcon = styled(MuiCheckCircleIcon)<IWrapper>(({ colors }) => ({
  fontSize: '50px',
  color: colors,
}));

export const CircleNotificationsIcon = styled(MuiCircleNotificationsIcon)<IWrapper>(({ colors }) => ({
  fontSize: '50px',
  color: colors,
}));
export const Link = styled('a')({
  fontSize: '16px',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#00A79D',
});

export const Email = styled('a')({
  fontSize: '16px',
  padding: '0 24px',
  textDecoration: 'none',
  cursor: 'pointer',
  color: '#00A79D',
});

export const TextFooter = styled('div')({
  margin: '0 5px 0 24px',
  color: 'rgba(58, 53, 65, 0.68)',
});

export const TooltipWrapper = styled('div')({
  width: '230px',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '16px',
});

export const GoItLink = styled(MuiLink)({
  fontSize: '12px',
  lineHeight: '15px',
  color: '#fff',
  fontWeight: '900',
  fontFamily: 'Inter',
  paddingTop: '6px',
}) as typeof MuiLink;
