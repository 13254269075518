import { styled } from '@mui/material/styles';

export const HelpBlock = styled('div')({
  width: '100%',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '50px',
  padding: '24px',
  borderRadius: '6px',
});

export const Title = styled('div')({
  fontWeight: '500',
  fontSize: '24px',
  color: 'rgba(58, 53, 65, 0.87)',
});

export const Text = styled('div')({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '20px',
  color: 'rgba(58, 53, 65, 0.68)',
});
