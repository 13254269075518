import { Button } from '@mui/material';
import { ConnectionType, ConnectionName } from '_constants';
import { ConnectionImg, ConnetionTitle, ConnetionText, ConnetionImgWrapper } from '../CGMLink.styles';
import { ConnetionBlockNeedCGMConn } from './GMCard.styles';

export const CGMModalCard = ({
  imgSrc,
  connectTo,
  handler,
}: {
  imgSrc: string;
  connectTo: ConnectionType;
  handler: (arg: ConnectionType) => void;
}) => (
  <ConnetionBlockNeedCGMConn>
    <ConnetionImgWrapper>
      <ConnectionImg src={imgSrc} />
    </ConnetionImgWrapper>
    <ConnetionTitle>{ConnectionName[connectTo]}</ConnetionTitle>
    <ConnetionText>Connect to {ConnectionName[connectTo]} to link your CGM data.</ConnetionText>
    <Button color="primary" size="normal" fullWidth variant="contained" onClick={() => handler(connectTo)}>
      Connect
    </Button>
  </ConnetionBlockNeedCGMConn>
);
