import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  CGIReadingWrapper,
  RangeSetupWrapper,
  RangeTitle,
  RangeLine,
  TrendingUpIcon,
  TrendingDownIcon,
  CheckIcon,
  RangeLineTextWrapper,
  RangeLineTitle,
  RangeLineCaption,
  SetRangeButton,
  RangeHint,
  CGIReadingBlock,
  CGIReadingBlockWrapper,
  CGIReadingTitle,
  CGIReadingValueWrapper,
  CGIReadingValue,
  CGIReadingMeasure,
  CGIReadingDate,
  CGIReadingTopBlock,
  ConnectionCodeWrapper,
  ConnectionCode,
  ConnectionCodeValue,
  ConnectionCodeIcon,
  ConnectionCodeValueWrapper,
  ConnectionCodeCaption,
  ContainerHead,
} from './Dashboard.styles';
import store from 'store';
import chart from 'assets/images/chart.png';
import SetRange from '../SetRange';
import { isMobile } from 'react-device-detect';
import { ConnectionStatus } from '_constants';

const convertDateToDdMmYyyyFormat = (date: string | undefined): string =>
  date ? new Date(date).toLocaleDateString('en-US') + ' ' + new Date(date).toLocaleTimeString('en-US') : '';

const Dashboard: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth / window.innerHeight < 1);
  const {
    setIsFirstLogin,
    currentUserReading,
    connectionCode,
    isFirstLogin,
    rangeSetupHigh,
    rangeSetupInRange,
    rangeSetupLow,
    rangeSetupUnit,
    currentCgmReadingValue,
    previousСgmReadingValue,
    lowFlash,
    highFlash,
    statusCGMConnectionStatus,
  } = store;

  const updateOrientation = useCallback(() => {
    setIsLandscape(window.innerWidth / window.innerHeight > 1);
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, [updateOrientation]);

  const init = useCallback(async () => {
    if (isFirstLogin) {
      setOpen(true);
    }
    setIsFirstLogin(false);
  }, [setIsFirstLogin, isFirstLogin]);

  useEffect(() => {
    init();
  }, [init]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerHead>
      <CGIReadingWrapper>
        <CGIReadingTopBlock>
          <CGIReadingBlock>
            <CGIReadingBlockWrapper>
              <CGIReadingTitle>Current CGM reading</CGIReadingTitle>
              <CGIReadingValueWrapper>
                <CGIReadingValue
                  fontsize={'100px'}
                  lineheight={'100px'}
                  color={currentUserReading?.currentCgmReading?.color}
                >
                  {currentCgmReadingValue}
                </CGIReadingValue>
                <CGIReadingMeasure>
                  {statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA && currentUserReading?.unit}
                </CGIReadingMeasure>
              </CGIReadingValueWrapper>
              <CGIReadingDate>
                {statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA
                  ? 'Last received on: ' + convertDateToDdMmYyyyFormat(currentUserReading?.currentCgmReading?.time)
                  : ''}
              </CGIReadingDate>
            </CGIReadingBlockWrapper>
          </CGIReadingBlock>
          <CGIReadingBlock>
            <CGIReadingBlockWrapper>
              <CGIReadingTitle>Previous CGM reading</CGIReadingTitle>
              <CGIReadingValueWrapper>
                <CGIReadingValue
                  fontsize={'72px'}
                  lineheight={'81px'}
                  color={currentUserReading?.previousСgmReading?.color}
                >
                  {previousСgmReadingValue}
                </CGIReadingValue>
                <CGIReadingMeasure>
                  {statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA && currentUserReading?.unit}
                </CGIReadingMeasure>
              </CGIReadingValueWrapper>
              <CGIReadingDate>
                {statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA
                  ? 'Last received on: ' + convertDateToDdMmYyyyFormat(currentUserReading?.previousСgmReading?.time)
                  : ''}
              </CGIReadingDate>
            </CGIReadingBlockWrapper>
          </CGIReadingBlock>
        </CGIReadingTopBlock>
        <ConnectionCodeWrapper>
          <ConnectionCode>
            <ConnectionCodeValueWrapper>
              <CGIReadingTitle>Connection code</CGIReadingTitle>
              <ConnectionCodeCaption>
                Enter this device code into your glowcose during setup. This number will remain the same for multiple
                devices.
              </ConnectionCodeCaption>
              <ConnectionCodeValue>{connectionCode}</ConnectionCodeValue>
            </ConnectionCodeValueWrapper>
            {(!isMobile || (isMobile && !isLandscape)) && <ConnectionCodeIcon src={chart} />}
          </ConnectionCode>
        </ConnectionCodeWrapper>
      </CGIReadingWrapper>
      <RangeSetupWrapper>
        <RangeTitle>{'Range setup'}</RangeTitle>
        <RangeLine>
          <TrendingUpIcon />
          <RangeLineTextWrapper>
            <RangeLineTitle>{rangeSetupHigh ? rangeSetupHigh + ' ' + rangeSetupUnit : '-'}</RangeLineTitle>
            <RangeLineCaption>{'High setpoint (flash ' + (highFlash ? 'ON)' : 'OFF)')}</RangeLineCaption>
          </RangeLineTextWrapper>
        </RangeLine>
        <RangeLine>
          <CheckIcon />
          <RangeLineTextWrapper>
            <RangeLineTitle>{rangeSetupInRange ? rangeSetupInRange + ' ' + rangeSetupUnit : '-'}</RangeLineTitle>
            <RangeLineCaption>{'In range setpoint'}</RangeLineCaption>
          </RangeLineTextWrapper>
        </RangeLine>
        <RangeLine>
          <TrendingDownIcon />
          <RangeLineTextWrapper>
            <RangeLineTitle>{rangeSetupLow ? rangeSetupLow + ' ' + rangeSetupUnit : '-'}</RangeLineTitle>
            <RangeLineCaption>{'Low setpoint (flash ' + (lowFlash ? 'ON)' : 'OFF)')}</RangeLineCaption>
          </RangeLineTextWrapper>
        </RangeLine>
        <SetRangeButton onClick={handleOpen} fullWidth variant="contained">
          Set range
        </SetRangeButton>
        <RangeHint>Changing setpoints will update the settings on your glowcose automatically</RangeHint>
      </RangeSetupWrapper>
      <SetRange open={open} setOpen={handleClose} />
    </ContainerHead>
  );
};

export default observer(Dashboard);
