import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
import { CountryDropdown } from 'react-country-region-selector';

export const TermsAndConditionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
  margin: '19px 0',
  fontSize: '14px',
  lineHeight: '18px',
});
export const TermsAndConditionsText = styled('div')({
  fontSize: '14px',
  paddingRight: '5px',
  lineHeight: '18px',
});
export const TermsAndConditionsLink = styled(Link)({
  fontSize: '14px',
  lineHeight: '18px',
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
}) as typeof Link;

export const CountrySelector = styled(CountryDropdown)({
  marginTop: '8px',
  fontSize: '16px',
  lineHeight: '56px',
  height: '56px',
  width: '100%',
  padding: '10px',
  ':hover': {
    borderColor: 'rgba(0, 0, 0, 0.8)',
  },
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
});
