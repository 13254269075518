import React from 'react';
import { observer } from 'mobx-react';
import { ConnetionBlock, ModalContenWrapper } from './ModalConnectToCGM.styles';
import { Modal } from '@mui/material';
import { CloseIconButton } from '../CGMLink.styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import store from 'store';
import DexcomForm from './DexcomForm';
import FreeStyleLibreForm from './FreeStyleLibreForm';
import NightscoutForm from './NightscoutForm';
import { ConnectionType } from '_constants';

interface Props {
  open: boolean;
  connection: ConnectionType;
  setOpen: (val: boolean) => void;
}

const modalMap: Record<ConnectionType, ({ onSubmit }: { onSubmit: () => void }) => JSX.Element> = {
  [ConnectionType.Dexcom]: DexcomForm,
  [ConnectionType.FreeStyleLibre]: FreeStyleLibreForm,
  [ConnectionType.Nightscout]: NightscoutForm,
  [ConnectionType.None]: () => <></>,
};

const ModalConnectToCGM: React.FC<Props> = ({ open = false, connection, setOpen }) => {
  const { getCGMConnectionStatus } = store;

  const handleClose = () => {
    getCGMConnectionStatus();
    setOpen(false);
  };

  const FormComponent = modalMap[connection];

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContenWrapper>
        <ConnetionBlock>
          <CloseIconButton onClick={handleClose}>
            <CloseRoundedIcon />
          </CloseIconButton>

          <FormComponent onSubmit={handleClose} />
        </ConnetionBlock>
      </ModalContenWrapper>
    </Modal>
  );
};

export default observer(ModalConnectToCGM);
