import { useState } from 'react';
import { observer } from 'mobx-react';
import { ConnectButtonWrapper, Link } from './ModalConnectToCGM.styles';
import { Button } from '@mui/material';
import { ConnectionImg, ConnetionTitle, ConnetionText } from '../CGMLink.styles';
import dexcom from 'assets/images/dexcom.png';
import { Textfield, Password } from 'components';
import store from 'store';

const DexcomForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { connectDescomShareApi } = store;

  const connect = async () => {
    await connectDescomShareApi(username, password);
    onSubmit();
  };

  return (
    <>
      <ConnectionImg src={dexcom} />
      <ConnetionTitle>Dexcom</ConnetionTitle>
      <ConnetionText>
        Use your Dexcom api credentials to link your data. Credentials can be found within the Dexcom app on the
        wearer's mobile device.
        <br />
        For additional help view the Setup Guide{' '}
        <Link target="_blank" href="https://glowcose.com/setupv2">
          here
        </Link>
        .
      </ConnetionText>
      <Textfield value={username} label={'Username'} setValue={setUsername} />
      <Password value={password} label={'Password'} setValue={setPassword} />
      <ConnectButtonWrapper>
        <Button
          color="primary"
          size="normal"
          disabled={!username || !password}
          fullWidth
          variant="contained"
          onClick={connect}
        >
          Submit
        </Button>
      </ConnectButtonWrapper>
    </>
  );
};

export default observer(DexcomForm);
