import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  ModalWrapper,
  ModalContenWrapper,
  ModalButtonWrapper,
  ModalTitle,
  ModalTextTop,
  ModalTextBottom,
  CloseIconButton,
} from 'views/Main/ModalLiveDevice/ModalLiveDevice.styles';
import { Modal, Button } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import store from 'store';
import { ConnectionType, ConnectionName } from '_constants';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ModalManualDisconnectCGM: React.FC<Props> = ({ open = false, onClose }) => {
  const { diconnectCGM, connectionType } = store;

  const onDisconnect = () => {
    diconnectCGM();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalWrapper>
        <ModalContenWrapper>
          <CloseIconButton onClick={onClose}>
            <CloseRoundedIcon />
          </CloseIconButton>
          <ModalTitle>Disconnect from the service</ModalTitle>
          <ModalTextBottom>
            Are you sure you want to disconnect from {ConnectionName[connectionType as ConnectionType]}?
          </ModalTextBottom>
          <ModalButtonWrapper>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: 180 }}
              color="primary"
              size="normal"
              disabled={false}
              variant="contained"
              onClick={onDisconnect}
            >
              Disconnect
            </Button>
          </ModalButtonWrapper>
        </ModalContenWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default observer(ModalManualDisconnectCGM);
