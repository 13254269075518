import React from 'react';
import { observer } from 'mobx-react';
import store from 'store';

import { TextField } from '@mui/material';
interface IPhone {
  value?: string;
  setValue?: (value: string) => void;
}

const Phone: React.FC<IPhone> = ({ value, setValue }) => {
  const { setPhone, phone } = store;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(e.currentTarget.value);
    } else {
      setPhone(e.currentTarget.value);
    }
  };
  return (
    <TextField
      value={setValue ? value : phone}
      onChange={onChange}
      fullWidth
      label="Phone Number"
      size="medium"
      margin="dense"
      inputProps={{ maxLength: 255 }}
    />
  );
};

export default observer(Phone);
