import { styled } from '@mui/material/styles';
import { Button, IconButton as MuiIconButton } from '@mui/material';

export const ModalWrapper = styled('div')({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  maxWidth: '448px',
  width: '100%',
  boxShadow: '24',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  outline: 'none',
});

export const ModalContenWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '48px 28px 36px',
});

export const ModalContenWrapperCGMconnection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  width: '100%',
  maxWidth: '860px',
  maxHeight: '100vh',
  boxShadow: '24',
  borderRadius: '6px',
  backgroundColor: '#FFFFFF',
  outline: 'none',
});

export const ModalTitle = styled('div')({
  color: 'rgba(58, 53, 65, 0.87)',
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: '600',
});

export const ModalTextTop = styled('div')({
  width: '100%',
  color: 'rgba(58, 53, 65, 0.68)',
  fontSize: '16px',
  lineHeight: '20px',
  margin: '4px 0 8px',
  textAlign: 'left',
});
export const ModalTextBottom = styled('div')({
  color: 'rgba(58, 53, 65, 0.68)',
  fontSize: '16px',
  lineHeight: '20px',
  marginBottom: '24px',
  textAlign: 'left',
});
export const ModalButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
});

export const CancelButton = styled(Button)({
  height: '42px',
  width: '140px',
  backgroundColor: '#00A79D',
  color: '#FFFFFF',
  ':hover': {
    color: '#D8FFFF',
    backgroundColor: '#64A7A3',
  },
}) as typeof Button;

export const CloseIconButton = styled(MuiIconButton)({
  position: 'absolute',
  top: '0',
  right: '0',
});

export const TextLink = styled('a')({
  fontSize: '16px',
  lineHeight: '20px',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#00A79D',
});
