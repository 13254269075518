import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, Outlet } from 'react-router-dom';
import { ROUTES, ConnectionStatus } from '_constants';
import {
  Wrapper,
  ContentWrapper,
  HeaderBlock,
  ContentBlock,
  ContainerHead,
  CGMConnectionBlock,
  TitleWrapper,
  Title,
  Icon,
  Caption,
  CheckCircleIcon,
  CircleNotificationsIcon,
  Link,
  AvatarWrapper,
  FooterBlock,
  MainBlock,
  Email,
  TextFooter,
  TooltipWrapper,
  GoItLink,
} from './Main.styles';
import Menu from './Menu';
import ModalLiveDevice from './ModalLiveDevice';
import ModalSupportEmail from './ModalSupportEmail';
import ModalCGMConnection from './ModalCGMConnection';
import store from 'store';
import { Avatar } from 'components';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConnectionName, ConnectionType } from '_constants/ConnectionStatus';

const Main: React.FC = () => {
  const {
    statusCGMConnectionStatus,
    liveDevices,
    connectionType,
    getCGMConnectionStatus,
    getLiveDevices,
    getCurrentUserReadings,
    getCurrentUserReadingWithTimeout,
  } = store;
  const navigate = useNavigate();

  const [openLiveDeviceModal, setOpenLiveDeviceModal] = useState(false);
  const [openSupportEmailModal, setOpenSupportEmailModal] = useState(false);
  const [openCGMConnectionModal, setOpenCGMConnectionModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const init = useCallback(async () => {
    await getCurrentUserReadings();
  }, [getCurrentUserReadings]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const timer = setInterval(getCurrentUserReadingWithTimeout, 60000);
    return () => clearTimeout(timer);
  }, [getCurrentUserReadingWithTimeout]);

  useEffect(() => {
    const timer = setInterval(getLiveDevices, 60000);
    return () => clearTimeout(timer);
  }, [getLiveDevices]);

  useEffect(() => {
    const timer = setInterval(getCGMConnectionStatus, 60000);
    return () => clearTimeout(timer);
  }, [getCGMConnectionStatus]);

  useEffect(() => {
    getCGMConnectionStatus();
    getLiveDevices();
  }, [getCGMConnectionStatus, getLiveDevices]);

  const gotoSupportEmail = () => {
    setOpenSupportEmailModal(true);
  };

  const gotoAccount = () => {
    navigate('/' + ROUTES.ACCOUNT);
  };
  const onTooltipClose = () => {
    setTooltipOpen(false);
  };

  const onTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };
  return (
    <Wrapper>
      <Menu />
      <ContentWrapper>
        <MainBlock>
          <HeaderBlock>
            {/* <NotificationsNoneIcon /> */}
            <AvatarWrapper onClick={gotoAccount}>
              <Avatar />
            </AvatarWrapper>
          </HeaderBlock>
          <ContentBlock>
            <ContainerHead>
              <CGMConnectionBlock>
                <TitleWrapper>
                  <Title>CGM connection</Title>
                  {statusCGMConnectionStatus && statusCGMConnectionStatus === ConnectionStatus.ALL_GOOD && (
                    <Caption>Your CGM is connected via {ConnectionName[connectionType as ConnectionType]}.</Caption>
                  )}
                  {statusCGMConnectionStatus && statusCGMConnectionStatus === ConnectionStatus.NO_CONNECTION_SETUP && (
                    <Caption>
                      Connection not yet established. Click{' '}
                      <Link onClick={() => setOpenCGMConnectionModal(true)}>here</Link> to connect.
                    </Caption>
                  )}
                  {statusCGMConnectionStatus && statusCGMConnectionStatus === ConnectionStatus.NO_CURRENT_DATA && (
                    <Caption>
                      Error fetching data. Click <Link onClick={gotoSupportEmail}>here</Link> to troubleshoot.
                    </Caption>
                  )}
                </TitleWrapper>
                <Icon>
                  {statusCGMConnectionStatus &&
                    (statusCGMConnectionStatus === ConnectionStatus.ALL_GOOD ? (
                      <CheckCircleIcon colors={'#00D5C9'} />
                    ) : (
                      <Icon>
                        <CircleNotificationsIcon colors={'#F4BF00'} />
                      </Icon>
                    ))}
                </Icon>
              </CGMConnectionBlock>
              <CGMConnectionBlock>
                <TitleWrapper>
                  <Title>Live devices</Title>
                  {!!liveDevices ? (
                    <Caption>You have {liveDevices} device(s) active</Caption>
                  ) : (
                    <Caption>
                      No devices are connected. Click <Link onClick={() => setOpenLiveDeviceModal(true)}>here</Link> for
                      details.{' '}
                      <Tooltip
                        color="secondary"
                        arrow
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={onTooltipClose}
                        open={tooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <TooltipWrapper>
                            Live devices are counted based on traffic rate from glowcose devices per minute. It may take
                            some time for the number of active units to update. <br />
                            <GoItLink component={'button'} underline="none" onClick={onTooltipClose}>
                              Got it
                            </GoItLink>
                          </TooltipWrapper>
                        }
                      >
                        <IconButton sx={{ marginBottom: '2px' }} onClick={onTooltipOpen}>
                          <InfoIcon sx={{ color: 'rgba(58, 53, 65, 0.35)' }} />
                        </IconButton>
                      </Tooltip>
                    </Caption>
                  )}
                </TitleWrapper>
                <Icon>
                  {!!liveDevices ? (
                    <CheckCircleIcon colors={'#00D5C9'} />
                  ) : (
                    <Icon>
                      <CircleNotificationsIcon colors={'#F4BF00'} />
                    </Icon>
                  )}
                </Icon>
              </CGMConnectionBlock>
            </ContainerHead>
            <Outlet />
          </ContentBlock>
        </MainBlock>
        <FooterBlock>
          <TextFooter>
            © Glowcose {dayjs().year()}. All rights reserved. By using this website you agree to the&nbsp;
            <Link target="_blank" href="http://www.glowcose.com/terms">
              terms and conditions
            </Link>
            {' and '}
            <Link target="_blank" href="http://glowcose.com/privacy">
              privacy policy
            </Link>
            .
          </TextFooter>
          <Email target="_blank" href="https://glowcose.com/pages/contact-us">
            Support
          </Email>
        </FooterBlock>
      </ContentWrapper>
      <ModalLiveDevice open={openLiveDeviceModal} setOpen={setOpenLiveDeviceModal} />
      <ModalSupportEmail open={openSupportEmailModal} setOpen={setOpenSupportEmailModal} />
      <ModalCGMConnection open={openCGMConnectionModal} setOpen={setOpenCGMConnectionModal} />
    </Wrapper>
  );
};

export default observer(Main);
