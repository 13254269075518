import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { Search as MuiSearchIcon, LockOpen as MuiLockOpenIcon } from '@mui/icons-material';

export const WrapperConnetions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 24px',
  padding: '0',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  position: 'relative',
});

export const StyledTab = styled(Tab)({
  height: '48px',
  minHeight: '48px',
  color: 'rgba(58, 53, 65, 0.68)',
  '&.Mui-selected': {
    color: '#00A79D',
  },
  '&.Mui-focusVisible': {
    color: '#000',
    backgroundColor: '#64A7A3',
  },
  ':hover': {
    color: '#FFF',
    backgroundColor: '#00A79D',
  },
});

export const SearchIcon = styled(MuiSearchIcon)({
  fontSize: '16px',
});

export const LockOpenIcon = styled(MuiLockOpenIcon)({
  fontSize: '16px',
});
