import { useEffect, useState, useMemo } from 'react';
import { useBlocker as routerUseBlocker } from 'react-router-dom';

const useBlocker = (initialIsChanged: boolean, setOpenModal: (val: boolean) => void) => {
  const [isChanged, setIsChanged] = useState(initialIsChanged || false);
  const blocker = routerUseBlocker(isChanged);
  useEffect(() => {
    if (blocker.state === 'blocked' && !isChanged) {
      blocker.reset();
    }
    if (blocker.state === 'blocked') {
      setOpenModal(true);
    }
  }, [blocker, isChanged, setOpenModal]);

  return useMemo(
    () => ({
      blocker,
      setIsChanged,
    }),
    [blocker, setIsChanged],
  );
};

export default useBlocker;
