import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import store from 'store';
import { ROUTES } from '_constants';
import { FullPageLoader, SnackbarMessage } from 'components';
import LoginScreen from 'views/Login/Login';
import Main from 'views/Main';
import Help from 'views/Help';
import SetDexcomCode from 'views/SetDexcomCode';
import NeedCGMConnect from 'views/NeedCGMConnect';
import ResetPassword from 'views/ResetPassword';
import ThemeProvider from 'utils/ThemeProvider';
import Dashboard from 'views/Main/Dashboard';
import CGMLink from 'views/Main/CGMLink';
import Account from 'views/Main/Account';

import LoginForm from 'views/Login/LoginForm';
import RegisterForm from 'views/Login/RegisterForm';
import ForgotPassword from 'views/Login/ForgotPassword';
import NoticeEmailCheck from 'views/Login/NoticeEmailCheck';
import ConfirmEmail from 'views/Login/ConfirmEmail';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={'/'}>
      <Route path={ROUTES.SETDEXCOMCODE} element={<SetDexcomCode />} />
      <Route path={ROUTES.NEEDDEXCOMELOGIN} element={<NeedCGMConnect />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.LOGIN} element={<LoginScreen />}>
        <Route index element={<LoginForm />} />
        <Route path={ROUTES.REGISTER} element={<RegisterForm />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.NOTICE_CHECK_EMAIL_AFTER_REGISTER} element={<NoticeEmailCheck />} />
        <Route path={ROUTES.NOTICE_CHECK_EMAIL_AFTER_FORGOT_PASS} element={<NoticeEmailCheck />} />
        <Route path={ROUTES.CONFIRMEMAIL} element={<ConfirmEmail />} />
      </Route>
      <Route path="/" element={<PrivateRoute />}>
        <Route path={ROUTES.HELP} element={<Help />} />
        <Route path="/" element={<Main />}>
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.CGM} element={<CGMLink />} />
          <Route path={ROUTES.ACCOUNT} element={<Account />} />
          <Route path={'/'} element={<Navigate to={ROUTES.DASHBOARD} />} />
        </Route>
      </Route>
      <Route path={'/*'} element={<Navigate to={ROUTES.DASHBOARD} />} />
    </Route>,
  ),
);
const App: React.FC = () => {
  const { init, isInit } = store;
  useEffect(() => {
    init();
  }, [init]);

  return (
    <ThemeProvider>
      <FullPageLoader />
      <SnackbarMessage />
      {isInit && <RouterProvider router={router} />}
    </ThemeProvider>
  );
};

export default observer(App);
