import { styled } from '@mui/material/styles';

export const ButtonWrapper = styled('div')({
  width: '100%',
  margin: '16px 0',
});
export const TitleWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
export const TitleIcon = styled('img')({
  height: '24px',
});
export const ArrowBack = styled('img')({
  height: '16px',
});
export const ArrowBackLink = styled('div')({
  color: '#00A79D',
  ':hover': {
    color: '#64A7A3',
  },
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '20px',
  height: '24px',
});
