/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import store from 'store';

export const API_CLIENT = axios.create({});

export const getBaseUrl = () => {
  return 'https://api.glowcose.io';
};

// Server query composer
export const request = async (path: string, params: any = {}, timeout = 60000) => {
  const url = getBaseUrl() + path;
  store.setErrMessage('');
  try {
    API_CLIENT.defaults.headers.common.Authorization = `Bearer ${store.accessToken}`;
    API_CLIENT.defaults.headers.common.Authorization = `Bearer ${store.accessToken}`;
    return await API_CLIENT({
      ...params,
      method: params.method,
      url,
      headers: params.headers,
      timeout,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.data.title) {
        store.setErrMessage(err.response?.data.title);
      } else {
        store.setErrMessage(err.message);
      }
    } else {
      store.setErrMessage('Network error');
    }
    // throw err;
    return { hasError: true };
  }
};
