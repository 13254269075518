import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  WrapperConnetions,
  ConnetionsHeader,
  ConnetionsText,
  Connetions,
  SkipButton,
  CloseIconButton,
  Logo,
  LogoWrapper,
  WrapperConnetionsNeedCGMConn,
  ConnetionsHeaderNeedCGMConn,
  ConnetionsTextNeedCGMConn,
  ConnetionsNeedCGMConn,
} from './CGMLink.styles';
import dexcom from 'assets/images/dexcom.png';
import freestyler from 'assets/images/freestylelibre.jpg';
import nightscout from 'assets/images/nightscout.png';
import store from 'store';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import logo from 'assets/icons/glowcose-logo.svg';
import ModalConnectToCGM from './ModalConnectToCGM';
import ModalManualDisconnectCGM from './ModalManualDisconnectCGM';
import ModalAutolDisconnectCGM from './ModalAutolDisconnectCGM';
import { ConnectionType } from '_constants';
import { CGMModalCard, CGMCard } from './CGMCard';

interface Props {
  isModal?: boolean;
  handleSkip?: () => void;
  isFirstLogin?: boolean;
}

const CGMLink: React.FC<Props> = ({ isModal, handleSkip, isFirstLogin }) => {
  const [openModalConnectToCGM, setOpenModalConnectToCGM] = useState(false);
  const [openModalManualDisconnectCGM, setOpenModalManualDisconnectCGM] = useState(false);
  const [openModalAutoDisconnectCGM, setOpenModalAutolDisconnectCGM] = useState(false);
  const [connectingTo, setConnectingTo] = useState<ConnectionType>();

  const { connectionType, completeSetDescomCode, setCompleteSetDescomCode } = store;

  const connect = (nextConnection: ConnectionType) => {
    setConnectingTo(nextConnection);

    if (nextConnection === ConnectionType.None) {
      setOpenModalManualDisconnectCGM(true);
      return;
    }

    if (connectionType === ConnectionType.None) {
      setOpenModalConnectToCGM(true);
    } else {
      setOpenModalAutolDisconnectCGM(true);
    }
  };

  useEffect(() => {
    if (completeSetDescomCode && isModal) {
      setCompleteSetDescomCode(false);
      if (handleSkip) {
        handleSkip();
      }
    }
    return () => setCompleteSetDescomCode(false);
  }, [completeSetDescomCode, isModal, setCompleteSetDescomCode, handleSkip]);

  return (
    <>
      {isModal ? (
        <WrapperConnetionsNeedCGMConn>
          <LogoWrapper>
            <Logo src={logo} alt="logo" />
          </LogoWrapper>
          <ConnetionsHeaderNeedCGMConn>
            {!isFirstLogin ? 'Your account has been Verified!' : 'CGM Linking'}
          </ConnetionsHeaderNeedCGMConn>
          <ConnetionsTextNeedCGMConn>
            {!isFirstLogin
              ? 'Link your CGM to provide data to your Glowcose.'
              : `Link your CGM to provide data to your Glowcose. This can be updated if needed at any time. Please keep in
            mind that changes to passwords will affect this connection. Only one CGM can be connected at a time.`}
          </ConnetionsTextNeedCGMConn>

          <ConnetionsNeedCGMConn>
            <CGMModalCard imgSrc={dexcom} connectTo={ConnectionType.Dexcom} handler={connect} />

            <CGMModalCard imgSrc={freestyler} connectTo={ConnectionType.FreeStyleLibre} handler={connect} />

            <CGMModalCard imgSrc={nightscout} connectTo={ConnectionType.Nightscout} handler={connect} />
          </ConnetionsNeedCGMConn>

          {handleSkip && <SkipButton onClick={handleSkip}>Skip</SkipButton>}
          {handleSkip && (
            <CloseIconButton onClick={handleSkip}>
              <CloseRoundedIcon />
            </CloseIconButton>
          )}
        </WrapperConnetionsNeedCGMConn>
      ) : (
        <WrapperConnetions>
          <ConnetionsHeader>CGM Linking</ConnetionsHeader>

          <ConnetionsText>
            Link your CGM to provide data to your Glowcose. This can be updated if needed at any time. Please keep in
            mind that changes to passwords will affect this connection. Only one CGM can be connected at a time.
          </ConnetionsText>

          <Connetions>
            <CGMCard
              imgSrc={dexcom}
              connectTo={ConnectionType.Dexcom}
              isConnectedTo={connectionType === ConnectionType.Dexcom}
              handler={connect}
            />

            <CGMCard
              imgSrc={freestyler}
              connectTo={ConnectionType.FreeStyleLibre}
              isConnectedTo={connectionType === ConnectionType.FreeStyleLibre}
              handler={connect}
            />

            <CGMCard
              imgSrc={nightscout}
              connectTo={ConnectionType.Nightscout}
              isConnectedTo={connectionType === ConnectionType.Nightscout}
              handler={connect}
            />
          </Connetions>
        </WrapperConnetions>
      )}

      {connectingTo && (
        <ModalConnectToCGM open={openModalConnectToCGM} setOpen={setOpenModalConnectToCGM} connection={connectingTo} />
      )}

      <ModalManualDisconnectCGM
        open={openModalManualDisconnectCGM}
        onClose={() => setOpenModalManualDisconnectCGM(false)}
      />

      {connectingTo && (
        <ModalAutolDisconnectCGM
          open={openModalAutoDisconnectCGM}
          setOpen={setOpenModalAutolDisconnectCGM}
          onComplete={() => setOpenModalConnectToCGM(true)}
          connectingTo={connectingTo}
        />
      )}
    </>
  );
};

export default observer(CGMLink);
