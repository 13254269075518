import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  WrapperConnetions,
  AvatarBlock,
  Avatar,
  ButtonsBlock,
  ButtonsWrapper,
  UploadButton,
  ResetButton,
  AvatarUploadCaption,
  TextFieldsBlock,
  TextFieldsLine,
  RightBlockWithPadding,
  DatepickerTextField,
  BottomButtonsWrapper,
  CancelButton,
  LeftBlockWithPadding,
} from './AccountContent.styles';
import { CountrySelector } from 'views/Login/RegisterForm/RegisterForm.styles';
import store from 'store';
import avatar from 'assets/images/avatar.png';
import { Textfield, Email, Phone } from 'components';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from '@mui/material';
import ConfirmNavigation from '../ConfirmNavigation';
import useBlocker from 'hooks/useBlocker';
import { DateValidationError } from '@mui/x-date-pickers/models';

const AccountContent: React.FC = () => {
  const { userFirstname, userLastname, userLocation, userAge, userPhone, saveUserData } = store;
  const [firstnameField, setFirstnameField] = useState('');
  const [lastnameField, setLastnameField] = useState('');
  const [age, setAge] = useState<Dayjs | null>(null);
  const [phoneField, setPhoneField] = useState('');
  const [location, setLocation] = useState('');
  const [ageError, setAgeError] = useState('');
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const clearChange = useCallback(async () => {
    setAgeError('');
    setFirstnameField(userFirstname);
    setLastnameField(userLastname);
    setAge(null);
    if (dayjs(userAge).isValid()) {
      /*
      TODO: 1)a valid date was received in the request
            2)manually delete part of the string, the date becomes invalid
            it is not possible to insert a valid date without clearing and timeout
      */
      await new Promise((resolve) => setTimeout(resolve, 200));
      setAge(dayjs(userAge));
    }
    setPhoneField(userPhone);
    setLocation(userLocation);
  }, [userFirstname, userLastname, userLocation, userAge, userPhone]);

  useEffect(() => {
    clearChange();
  }, [clearChange, userAge]);

  useEffect(() => {
    setAgeError('');
    if (age && !age?.isValid()) {
      setAgeError('Date is not valid');
    } else {
      if (age)
        if (dayjs().year() - age?.year() < 18) {
          setAgeError('User must be over the age of 18');
        }
    }
  }, [age]);

  useEffect(() => {
    setIsFieldsChanged(
      userFirstname !== firstnameField ||
        userLastname !== lastnameField ||
        (age && age.toString() !== dayjs(userAge).toString()) ||
        userPhone !== phoneField ||
        location !== userLocation ||
        (!age && !!userAge),
    );
  }, [
    userFirstname,
    firstnameField,
    userLastname,
    lastnameField,
    age,
    userAge,
    userPhone,
    phoneField,
    location,
    userLocation,
  ]);

  const saveData = async () => {
    if (isFieldsChanged && !ageError) {
      await saveUserData(firstnameField, lastnameField, phoneField, location, age ? age.format('MM-DD-YYYY') : null);
    }
  };

  const setUserAge = (value: Dayjs | null) => {
    if (value === null || value?.isValid()) {
      setAge(value);
    }
  };

  const { blocker, setIsChanged } = useBlocker(isFieldsChanged && !ageError, setOpenModal);
  useEffect(() => {
    setIsChanged(isFieldsChanged && !ageError);
  }, [setIsChanged, isFieldsChanged, ageError]);

  const handleSave = async () => {
    await setOpenModal(false);
    await saveData();
    await blocker.proceed?.();
  };
  const handleDiscard = () => {
    setOpenModal(false);
    blocker.proceed?.();
  };

  const onAgeError = (error: DateValidationError) => {
    if (error === 'invalidDate') {
      setAgeError('Date is not valid');
    }
  };

  return (
    <WrapperConnetions>
      {false && (
        <AvatarBlock>
          <Avatar src={avatar} />
          <ButtonsBlock>
            <ButtonsWrapper>
              <UploadButton variant="contained">
                Upload new photo
                <input hidden accept="image/*" multiple type="file" />
              </UploadButton>
              <ResetButton variant="outlined">Reset</ResetButton>
            </ButtonsWrapper>
            <AvatarUploadCaption>Allowed JPG, GIF or PNG. Max size of 800K</AvatarUploadCaption>
          </ButtonsBlock>
        </AvatarBlock>
      )}
      <TextFieldsBlock>
        <TextFieldsLine>
          <RightBlockWithPadding>
            <Textfield value={firstnameField} label={'First name'} setValue={setFirstnameField} />
          </RightBlockWithPadding>
          <LeftBlockWithPadding>
            <Textfield value={lastnameField} label={'Last name'} setValue={setLastnameField} />
          </LeftBlockWithPadding>
        </TextFieldsLine>
        <TextFieldsLine>
          <RightBlockWithPadding>
            <Email disabled={true} isNeedValid={true} />
          </RightBlockWithPadding>
          <DatepickerTextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={age}
                sx={{
                  width: '100%',
                  '.MuiFormHelperText-root': {
                    color: '#d32f2f',
                  },
                }}
                onError={(newError) => onAgeError(newError)}
                slotProps={{
                  textField: {
                    helperText: ageError,
                  },
                }}
                format={'MM-DD-YYYY'}
                label={'Date of birth'}
                onChange={setUserAge}
              />
            </LocalizationProvider>
          </DatepickerTextField>
          {/*<Textfield value={age} label={'Age'} validateError={ageError} setValue={setUserAge} />*/}
        </TextFieldsLine>
        <TextFieldsLine>
          <RightBlockWithPadding>
            <CountrySelector value={location} onChange={setLocation} priorityOptions={['US']} valueType="short" />
          </RightBlockWithPadding>
          <LeftBlockWithPadding>
            <Phone value={phoneField} setValue={setPhoneField} />
          </LeftBlockWithPadding>
        </TextFieldsLine>
      </TextFieldsBlock>
      <BottomButtonsWrapper>
        <Button
          color="primary"
          size="smaller"
          disabled={!(isFieldsChanged && !ageError.length)}
          variant="contained"
          onClick={saveData}
        >
          Save Changes
        </Button>
        <CancelButton
          color="secondary"
          size="smaller"
          disabled={!isFieldsChanged && !ageError.length}
          variant="outlined"
          onClick={clearChange}
        >
          Cancel
        </CancelButton>
      </BottomButtonsWrapper>
      {blocker ? <ConfirmNavigation open={openModal} handleDiscard={handleDiscard} handleSave={handleSave} /> : null}
    </WrapperConnetions>
  );
};

export default observer(AccountContent);
