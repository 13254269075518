import { types } from 'mobx-state-tree';
import { loginApi } from 'api';
import { Instance } from 'mobx-state-tree';
import { RangeTypes, ConnectionStatus, ConnectionType } from '_constants';
import { ConnectionName } from '_constants/ConnectionStatus';

const MCgmReading = types.model({
  value: types.number,
  time: types.string,
  color: types.string,
});

const MCUrrentUserReading = types.model({
  unit: types.optional(types.string, ''),
  currentCgmReading: types.maybeNull(MCgmReading),
  previousСgmReading: types.maybeNull(MCgmReading),
});
const MRangeSetup = types.model({
  unit: types.optional(types.string, ''),
  high: types.optional(types.number, 0),
  inRange: types.optional(types.number, 0),
  low: types.optional(types.number, 0),
});
const MUserSettings = types.model({
  highFlash: types.optional(types.boolean, false),
  lowFlash: types.optional(types.boolean, false),
});

const MCUrrentUser = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  location: types.string,
  connectionCode: types.maybeNull(types.string),
  rangeSetup: types.maybeNull(MRangeSetup),
  emailConfirmed: types.maybeNull(types.boolean),
  dateOfBirth: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  originalPhoto: types.maybeNull(types.string),
  resizedPhoto: types.maybeNull(types.string),
  userSettings: MUserSettings,
});

const Store = types
  .model({
    isInit: false,
    accessToken: types.optional(types.string, ''),
    refreshToken: '',
    firstTimeLoggedIn: false,
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    phone: '',
    country: '',
    showLoader: false,
    errMessage: '',
    noticedMessage: '',
    currentUserReading: types.maybeNull(MCUrrentUserReading),
    currentUser: types.maybeNull(MCUrrentUser),
    dexcomLoginUrl: '',
    completeSetDescomCode: false,
    needShowNoticeCheckEmail: false,
    rangeType: types.optional(types.enumeration<RangeTypes>('Rage', Object.values(RangeTypes)), RangeTypes.MG),
    isFirstLogin: false,
    statusCGMConnectionStatus: '',
    connectionType: ConnectionType.None,
    liveDevices: types.maybeNull(types.number),
    completeResetPassword: false,
  })
  .views((self) => ({
    get hasAuth() {
      return self.accessToken && !!self.accessToken.length;
    },
    get isEmailValid() {
      const validateEmail = (email: string) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!email && !re.test(email);
      };

      return !validateEmail(self.email);
    },
    get isFirstnameValid() {
      return self.firstname && self.firstname.length >= 2 && self.firstname.length < 255;
    },
    get isLastnameValid() {
      return self.lastname && self.lastname.length >= 2 && self.lastname.length < 255;
    },
  }))
  .views((self) => ({
    get getLastnameError() {
      if (!self.isLastnameValid) return 'Last name must be at least 2 characters long';
      return '';
    },
    get getFirstnameError() {
      if (!self.isFirstnameValid) return 'First name must be at least 2 characters long';
      return '';
    },
    get connectionCode() {
      return self.currentUser?.connectionCode ? self.currentUser?.connectionCode.toLocaleLowerCase() : '';
    },
    get rangeSetupHigh() {
      if (self.currentUser?.rangeSetup?.unit === RangeTypes.MMOL) {
        return self.currentUser?.rangeSetup?.high ? self.currentUser?.rangeSetup?.high.toFixed(2) : '';
      } else {
        return self.currentUser?.rangeSetup?.high;
      }
    },
    get rangeSetupInRange() {
      if (self.currentUser?.rangeSetup?.unit === RangeTypes.MMOL) {
        return self.currentUser?.rangeSetup?.inRange ? self.currentUser?.rangeSetup?.inRange.toFixed(2) : '';
      } else {
        return self.currentUser?.rangeSetup?.inRange;
      }
    },
    get rangeSetupLow() {
      if (self.currentUser?.rangeSetup?.unit === RangeTypes.MMOL) {
        return self.currentUser?.rangeSetup?.low ? self.currentUser?.rangeSetup?.low.toFixed(2) : '';
      } else {
        return self.currentUser?.rangeSetup?.low;
      }
    },
    get rangeSetupUnit() {
      return self.currentUser?.rangeSetup?.unit || '';
    },
    get currentCgmReadingValue() {
      if (self.statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA) {
        if (self.currentUser?.rangeSetup?.unit === RangeTypes.MMOL) {
          return self.currentUserReading?.currentCgmReading?.value
            ? self.currentUserReading?.currentCgmReading?.value.toFixed(2)
            : '';
        } else {
          return self.currentUserReading?.currentCgmReading?.value;
        }
      } else {
        return '';
      }
    },
    get previousСgmReadingValue() {
      if (self.statusCGMConnectionStatus !== ConnectionStatus.NO_CURRENT_DATA) {
        if (self.currentUser?.rangeSetup?.unit === RangeTypes.MMOL) {
          return self.currentUserReading?.previousСgmReading?.value
            ? self.currentUserReading?.previousСgmReading?.value.toFixed(2)
            : '';
        } else {
          return self.currentUserReading?.previousСgmReading?.value;
        }
      } else {
        return '';
      }
    },
    get userAge() {
      return self.currentUser?.dateOfBirth ? self.currentUser?.dateOfBirth : null;
    },
    get userGender() {
      return self.currentUser?.gender || '';
    },
    get userLocation() {
      return self.currentUser?.location || '';
    },
    get userPhone() {
      return self.currentUser?.phoneNumber ? self.currentUser?.phoneNumber : '';
    },
    get userFirstname() {
      return self.currentUser?.firstName ? self.currentUser?.firstName : '';
    },
    get userLastname() {
      return self.currentUser?.lastName ? self.currentUser?.lastName : '';
    },
    get highFlash() {
      return self.currentUser?.userSettings.highFlash || false;
    },
    get lowFlash() {
      return self.currentUser?.userSettings.lowFlash || false;
    },
  }))
  .actions((self) => ({
    setIsInit: (isInit: boolean) => {
      self.isInit = isInit;
    },
    setToken: (token: string) => {
      self.accessToken = token;
    },
    setFirstname: (firstname: string) => {
      self.firstname = firstname.trim();
    },
    setLastname: (lastname: string) => {
      self.lastname = lastname.trim();
    },
    setPassword: (password: string) => {
      self.password = password;
    },
    setEmail: (email: string) => {
      self.email = email.trim();
    },
    setPhone: (phone: string) => {
      self.phone = phone.trim();
    },
    setCountry: (country: string) => {
      self.country = country.trim();
    },
    setShowLoader: (showLoader: boolean) => {
      self.showLoader = showLoader;
    },
    setErrMessage: (message: string) => {
      self.errMessage = message;
    },
    setCurrentUserReading: (currentUserReading: Instance<typeof MCUrrentUserReading> | null) => {
      self.currentUserReading = currentUserReading;
    },
    setCurrentUser: (currentUser: Instance<typeof MCUrrentUser> | null) => {
      self.currentUser = currentUser;
    },
    setLoginUrl: (dexcomLoginUrl: string) => {
      self.dexcomLoginUrl = dexcomLoginUrl;
    },
    setCompleteSetDescomCode: (completeSetDescomCode: boolean) => {
      self.completeSetDescomCode = completeSetDescomCode;
    },
    setNoticedMessage: (noticedMessage: string) => {
      self.noticedMessage = noticedMessage;
    },
    setRangeType: (rangeType: RangeTypes) => {
      self.rangeType = rangeType;
    },
    setNeedShowNoticeCheckEmail: (needShowNoticeCheckEmail: boolean) => {
      self.needShowNoticeCheckEmail = needShowNoticeCheckEmail;
    },
    setIsFirstLogin: (isFirstLogin: boolean) => {
      self.isFirstLogin = isFirstLogin;
      localStorage.setItem('isFirstLogin', String(isFirstLogin));
    },
    setRangeSetup: (rangeSetup: Instance<typeof MRangeSetup>) => {
      if (self.currentUser) {
        self.currentUser.rangeSetup = rangeSetup;
      }
    },
    setFirstTimeLoggedIn: (firstTimeLoggedIn: boolean) => {
      self.firstTimeLoggedIn = firstTimeLoggedIn;
    },
    setCGMConnectionStatus: (statusCGMConnectionStatus: string, connectionType: ConnectionType) => {
      self.statusCGMConnectionStatus = statusCGMConnectionStatus;
      self.connectionType = connectionType;
    },
    setLiveDevices: (liveDevices: number) => {
      self.liveDevices = liveDevices;
    },
    setUserAge: (userAge: string) => {
      if (self.currentUser) {
        self.currentUser.dateOfBirth = userAge;
      }
    },
    setUserGender: (gender: string) => {
      if (self.currentUser) {
        self.currentUser.gender = gender;
      }
    },
    setCompleteResetPassword: (completeResetPassword: boolean) => {
      self.completeResetPassword = completeResetPassword;
    },
  }))
  .actions((self) => ({
    init: async () => {
      self.setShowLoader(true);
      const accessToken = await localStorage.getItem('accessToken');
      const isFirstLogin = await localStorage.getItem('isFirstLogin');
      self.setIsFirstLogin(isFirstLogin === 'true');
      if (accessToken) {
        self.setToken(accessToken);
      }
      self.setIsInit(true);
      self.setShowLoader(false);
    },
    createAccount: async () => {
      self.setShowLoader(true);
      const sendData = {
        firstname: self.firstname || null,
        lastname: self.lastname || null,
        email: self.email,
        password: self.password,
        location: self.country,
        phoneNumber: self.phone || null,
      };

      const { hasError } = await loginApi.createAccount(sendData);
      if (!hasError) {
        self.setNeedShowNoticeCheckEmail(true);
      }

      self.setShowLoader(false);
    },
    signIn: async () => {
      self.setShowLoader(true);
      const data = await loginApi.signIn({
        email: self.email,
        password: self.password,
      });
      if (data) {
        self.setFirstTimeLoggedIn(data.firstTimeLoggedIn);
        self.setToken(data.accessToken);
        localStorage.setItem('accessToken', data.accessToken);
      }
      self.setShowLoader(false);
    },
    clearAuth: () => {
      self.setCountry('');
      self.setEmail('');
      self.setPassword('');
      self.setFirstname('');
      self.setLastname('');
      self.setCurrentUser(null);
    },
    logout: async () => {
      self.setShowLoader(true);
      await loginApi.signOut();
      self.setToken('');
      localStorage.removeItem('accessToken');
      self.setShowLoader(false);
    },
    getCurrentUserReadings: async () => {
      self.setShowLoader(true);
      const currentUserReading = await loginApi.getCurrentUserReadings();

      self.setCurrentUserReading(currentUserReading.data || null);
      if (currentUserReading.data) {
        self.setRangeType(currentUserReading.data.unit);
      }
      const currentUser = await loginApi.getCurrentUser();
      if (currentUser.data) {
        self.setCurrentUser(currentUser.data);
        self.setEmail(currentUser.data.email);
      }
      self.setShowLoader(false);
    },
    getCurrentUserReadingWithTimeout: async () => {
      const currentUserReading = await loginApi.getCurrentUserReadings();
      if (currentUserReading.data) {
        self.setCurrentUserReading(currentUserReading.data);
      }
    },
    getDexcomeLoginUrl: async () => {
      self.setShowLoader(true);
      const { data } = await loginApi.getDexcomeLoginUrl();
      if (data && data.loginUrl) {
        self.setLoginUrl(data.loginUrl);
      }
      self.setShowLoader(false);
    },
    setDexcomeCode: async (dexcomCode: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.setDexcomeCode({ code: dexcomCode });

      if (!hasError) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        self.setCompleteSetDescomCode(true);
      }
      self.setShowLoader(false);
    },
    setRange: async (unit: string, high: string, inRange: string, low: string) => {
      self.setShowLoader(true);
      const { data } = await loginApi.saveRange({ unit: unit, high: high, inRange: inRange, low: low });

      if (data) {
        self.setRangeSetup(data);
      }

      self.setShowLoader(false);
    },
    getCGMConnectionStatus: async () => {
      const { hasError, data } = await loginApi.getCGMConnectionStatus();

      if (hasError) {
        self.setCGMConnectionStatus(ConnectionStatus.NO_CURRENT_DATA, ConnectionType.None);
      }
      if (data && data.status) {
        self.setCGMConnectionStatus(data.status, data.connectionType);
      }
    },
    getLiveDevices: async () => {
      const { data } = await loginApi.getLiveDevices();
      if (data && data.liveDevices) {
        self.setLiveDevices(data.liveDevices);
      }
    },

    savePassword: async (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.savePassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
      if (!hasError) {
        self.setNoticedMessage('Your password has been changed successfully');
      }

      self.setShowLoader(false);
    },

    sendResetEmail: async () => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.sendResetEmail({
        email: self.email,
      });
      if (!hasError) {
        self.setNeedShowNoticeCheckEmail(true);
      }
      self.setShowLoader(false);
    },
    resetPassword: async (email: string, token: string, newPassword: string, confirmNewPassword: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.resetPassword({
        email: email,
        token: token,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
      if (hasError) {
        self.setCompleteResetPassword(true);
      }
      self.setShowLoader(false);
    },
    setUserSettings: async (lowFlash: boolean, highFlash: boolean) => {
      self.setShowLoader(true);
      await loginApi.setUserSettings({
        lowFlash: lowFlash,
        highFlash: highFlash,
      });
      self.setShowLoader(false);
    },
  }))
  .actions((self) => ({
    saveUserData: async (
      firstName: string,
      lastName: string,
      phoneNumber: string,
      location: string,
      age: string | null,
    ) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.saveUserData({
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        location: location,
        dateOfBirth: age,
        photo: null,
        resetUserPhoto: null,
      });
      if (!hasError) {
        const currentUser = await loginApi.getCurrentUser();
        self.setCurrentUser(currentUser.data);
        self.setEmail(currentUser.data.email);
        self.getCGMConnectionStatus();
      }
      self.setShowLoader(false);
    },
    connectDescomShareApi: async (username: string, password: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.connectDescomShareApi({
        username,
        password,
      });
      if (hasError) {
        self.setErrMessage(hasError?.message ?? 'Something went wrong');
      } else {
        self.getCurrentUserReadings();
        self.setCompleteSetDescomCode(true);
        self.setNoticedMessage('You have successfully connected to Dexcom');
      }
      self.setShowLoader(false);
    },
    connectFreeStyleLibreApi: async (username: string, password: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.connectFreeStyleLibreApi({
        username,
        password,
      });
      if (hasError) {
        self.setErrMessage(hasError?.message ?? 'Something went wrong');
      } else {
        self.getCurrentUserReadings();
        self.setCompleteSetDescomCode(true);
        self.setNoticedMessage('You have successfully connected to Freestyle Libre');
      }
      self.setShowLoader(false);
    },
    connectNightscoutApi: async (endpoint: string, apiSecret: string) => {
      self.setShowLoader(true);
      const { hasError } = await loginApi.connectNightscoutApi({
        endpoint,
        apiSecret,
      });
      if (hasError) {
        self.setErrMessage(hasError?.message ?? 'Something went wrong');
      } else {
        self.getCurrentUserReadings();
        self.setCompleteSetDescomCode(true);
        self.setNoticedMessage('You have successfully connected to Nightsout');
      }
      self.setShowLoader(false);
    },
    diconnectCGM: async () => {
      self.setShowLoader(true);

      const message = `You are successfully disconnected from ${
        ConnectionName[self.connectionType as ConnectionType]
      }.`;

      const { hasError } = await loginApi.diconnectCGM();

      if (hasError) {
        self.setErrMessage(hasError?.message ?? 'Something went wrong');
      } else {
        await self.getCGMConnectionStatus();
        await self.getCurrentUserReadings();
        self.setNoticedMessage(message);
      }

      self.setShowLoader(false);
    },
  }));

export type TStore = Instance<typeof Store>;

export default Store;
