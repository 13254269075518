import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import store from 'store';
import { Content, Title } from '../Login.styles';
import { ROUTES } from '_constants';
import { Caption, ArrowBack, ArrowBackLink, TitleWrapper, Email } from './NoticeEmailCheck.styles';
import arrow from 'assets/icons/arrow-back.svg';

const NoticeEmailCheck: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, clearAuth } = store;

  const gotoLogin = () => {
    clearAuth();
    navigate('/' + ROUTES.LOGIN);
  };

  return (
    <Content>
      <TitleWrapper>
        <Title>Please check your email</Title>
      </TitleWrapper>
      {location.pathname.includes(ROUTES.NOTICE_CHECK_EMAIL_AFTER_FORGOT_PASS) ? (
        <Caption>
          We’ve sent you an email to <Email href={'mailto:' + email}>{email}</Email>. Please follow the instructions in
          the email to reset your password.
        </Caption>
      ) : (
        <Caption>
          We’ve sent you an email to <Email href={'mailto:' + email}>{email}</Email> with a link to confirm your
          account. Please follow the instructions in the email to continue with glowcose.
        </Caption>
      )}
      <ArrowBackLink onClick={gotoLogin}>
        <ArrowBack src={arrow} alt="Back to login" /> Back to login
      </ArrowBackLink>
    </Content>
  );
};

export default observer(NoticeEmailCheck);
