export enum ConnectionStatus {
  ALL_GOOD = 'allGood',
  NO_CURRENT_DATA = 'noCurrentData',
  NO_CONNECTION_SETUP = 'noConnectionSetup',
}

export enum ConnectionType {
  Dexcom = 'dexcom',
  FreeStyleLibre = 'freeStyleLibre',
  Nightscout = 'nightScout',
  None = 'none',
}

export const ConnectionName: Partial<Record<ConnectionType, string>> = {
  [ConnectionType.Dexcom]: 'Dexcom',
  [ConnectionType.FreeStyleLibre]: 'FreeStyle Libre',
  [ConnectionType.Nightscout]: 'Nightscout',
};

export default ConnectionStatus;
