import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '_constants';
import { Wrapper, ContentWrapper } from './NeedCGMConnect.styles';
import CGMLink from 'views/Main/CGMLink';
import store from 'store';

const NeedCGMConnect: React.FC = () => {
  const { setIsFirstLogin } = store;
  const navigate = useNavigate();

  useEffect(() => {
    setIsFirstLogin(true);
  }, [setIsFirstLogin]);

  const gotoDashboard = () => {
    navigate('/' + ROUTES.DASHBOARD);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <CGMLink isModal={true} handleSkip={gotoDashboard} />
      </ContentWrapper>
    </Wrapper>
  );
};

export default observer(NeedCGMConnect);
