import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Wrapper,
  LogoWrapper,
  Logo,
  MenuList,
  List,
  ListItem,
  LogoutWrapper,
  LogoutText,
  ModalContenWrapper,
  ModalButtonWrapper,
  CancelButton,
  LogoutButton,
  ModalTitle,
  ModalText,
  ShowMenuButton,
  CloseIconButton,
} from './Menu.styles';
import store from 'store';
import { ROUTES } from '_constants';
import logo from 'assets/icons/glowcose-logo-white.svg';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import LogoutIcon from '@mui/icons-material/Logout';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { isMobile } from 'react-device-detect';
import Drawer from '@mui/material/Drawer';

const menuItems = [
  {
    id: 1,
    title: 'Dashboard',
    icon: <HomeIcon />,
    route: ROUTES.ROOT + ROUTES.DASHBOARD,
  },
  {
    id: 2,
    title: 'CGM Link',
    icon: <TripOriginIcon fontSize="small" />,
    route: ROUTES.ROOT + ROUTES.CGM,
  },
  {
    id: 3,
    title: 'Account',
    icon: <TripOriginIcon fontSize="small" />,
    route: ROUTES.ROOT + ROUTES.ACCOUNT,
  },
  {
    id: 4,
    title: 'Help',
    icon: <TripOriginIcon fontSize="small" />,
    route: ROUTES.ROOT + ROUTES.HELP,
  },
];

const Menu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();
  const { logout } = store;

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onLogout = () => {
    handleClose();
    logout();
  };

  return (
    <>
      {isMobile && !showMenu && (
        <ShowMenuButton>
          <IconButton
            color="info"
            sx={{ backgroundColor: '#fff', boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.1)' }}
            onClick={() => setShowMenu(true)}
          >
            <MenuRoundedIcon fontSize="large" />
          </IconButton>
        </ShowMenuButton>
      )}
      <Wrapper width={isMobile ? '0px' : '260px'}>
        <Drawer
          anchor={'left'}
          variant={!isMobile ? 'permanent' : 'temporary'}
          open={showMenu}
          hideBackdrop={false}
          onClick={() => setShowMenu(false)}
          onClose={() => setShowMenu(false)}
        >
          <LogoWrapper>
            <Logo src={logo} alt="logo" />
          </LogoWrapper>
          <MenuList>
            <List>
              {menuItems.map((menuItem) => (
                <ListItem key={menuItem.id} disablePadding>
                  <ListItemButton
                    selected={location.pathname === menuItem.route}
                    onClick={() => navigate(menuItem.route)}
                  >
                    <ListItemIcon>{menuItem.icon}</ListItemIcon>
                    <ListItemText primary={menuItem.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <LogoutWrapper>
              <LogoutIcon />
              <LogoutText underline={'none'} component={'button'} onClick={handleOpen}>
                {'Logout'}
              </LogoutText>
            </LogoutWrapper>
          </MenuList>
        </Drawer>
        <Modal open={open} onClose={handleClose}>
          <ModalContenWrapper>
            <CloseIconButton onClick={handleClose}>
              <CloseRoundedIcon />
            </CloseIconButton>
            <ModalTitle>Logout</ModalTitle>
            <ModalText>
              Are you sure you want to log out? <br />
              Glowcose will continue while logged out.
            </ModalText>
            <ModalButtonWrapper>
              <LogoutButton onClick={onLogout}>Log out</LogoutButton>
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </ModalButtonWrapper>
          </ModalContenWrapper>
        </Modal>
      </Wrapper>
    </>
  );
};

export default observer(Menu);
