import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '_constants';
import { Wrapper } from './SetDexcomCode.styles';
import store from 'store';

const SetDexcomCode: React.FC = () => {
  const { setDexcomeCode, setNoticedMessage, completeSetDescomCode } = store;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const dexcomCode = searchParams.get('code');
    if (dexcomCode) {
      setDexcomeCode(dexcomCode);
    }
  }, [setDexcomeCode, searchParams]);
  useEffect(() => {
    if (completeSetDescomCode) {
      setNoticedMessage('Connect to Dexcom  was successful');
      navigate('/' + ROUTES.DASHBOARD);
    }
  }, [completeSetDescomCode, navigate, setNoticedMessage]);

  return <Wrapper></Wrapper>;
};

export default observer(SetDexcomCode);
